import React from "react";
import Logo from "../components/Logo";
class Footer extends React.Component{
    render(){
        return(
            <>
            <footer>
                <Logo />
                <p>Termo de Privacidade</p>
            </footer>
            </>
        );
    }
}

export default Footer;