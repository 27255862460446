import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import MultipleItems from "./slider";
class ListaFeeds extends React.Component{
    render(){
        return(
            
       <>
       <div className="container">
       <div className="col-12 d-flex justify-content-center">
            <MultipleItems/>
       </div>
       </div>
    
       </>

        );
    }
}

export default ListaFeeds;

            
