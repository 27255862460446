import React from "react";
import ImgFotoPerfil from "../assets/images/Foto-perfil.png";

class FotoPerfil extends React.Component{
    render(){
        return(
            <>
            <a className="b-radius" href="/">
              <img width={150} src={ImgFotoPerfil}/>
            </a>
            </>

        );
    }
}

export default FotoPerfil;