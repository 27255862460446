import Headers from '../Inc/Headers';
import Footer from '../Inc/Footer';

export function Login() {

    return (
      <>

      <Headers />
      <div id='main'>
          <h1>Ola Login</h1>
          <h1>Ola Login</h1>
      </div>
      <Footer />
      </> 
    ) 
  }
  export default Login;  