import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Perfil from "../pages/Perfil";
import Login from "../pages/Login";
import Privacidade from "../pages/Privacidade";
import Configuracoes from "../pages/Configuracoes";


const Router = () =>{
    return(
        <BrowserRouter>
            <Routes>
                <Route Component={ Home } path="/" exact />
                <Route Component={ Perfil } path="/Perfil" />
                <Route Component={ Login } path="/Login" />
                <Route Component={ Privacidade } path="/Privacidade" />
                <Route Component={ Configuracoes } path="/Configuracoes" />
            </Routes>
        </BrowserRouter>
    );
}

export default Router; 