import React from "react";
import FotoPerfil from "./FotoPerfil";
import Coments from "./Coments";
import Share from "./share";
class Posts extends React.Component{
    render(){
        return(
            <>

            <div className="container">
                <div className="post-item">
                    <div className="post-perfil d-flex ">
                        <div className="post-perfil-foto col-2">
                        <FotoPerfil />
                        </div>
                        <div className="post-perfil col-10">
                            <h2>@Fulana de tal alterado</h2>
                            <p>Hoje o dia foi lindo</p>
                        </div>
                    </div>
                    <div className="post-perfil col-10 d-flex">
                        <div className="post-thumb col-12 offset-md-1">
                            <div className="post-thumb col-12 mb-3">
                                <img src="https://placehold.co/380x166"/>
                                <Share />
                            </div>
                            <Coments />
                            <Coments />
                            
                        </div>
                    </div>
                </div>
            </div>
            </>

        );
    }
}

export default Posts;