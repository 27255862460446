import React from "react";
import { Link } from "react-router-dom";

class Menu extends React.Component{
    render(){
        return(
            <>
            <nav className="menu-principal">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/Perfil">Perfil</Link>
                    </li>
                    <li>
                        <Link to="/Privacidade">Privacidade</Link>
                    </li>
                    <li>
                        <Link to="/Login">Login</Link>
                    </li>
                    <li>
                        <Link to="/Configuracoes">Configurações</Link>
                    </li>
                </ul>
            </nav>
            </>

        );
    }
}

export default Menu;