import Headers from '../Inc/Headers';
import Footer from '../Inc/Footer';

const Privacidade = () =>{
  return(
    <>
          <Headers />
          <div id='main'>

            <h1>Olá Privacidade</h1>
          
          </div>
          <Footer />
      
    </>
  )
}

export default Privacidade;