import React from "react";
import Navmenu_2 from "../components/Navmenu_2";
import Logo from "../components/Logo";
import FotoPerfil from "../components/FotoPerfil";
import FormSearch from "../components/FormSearch";
class Headers extends React.Component{
    render(){
        return(
            <>
            <header className="App-header">
              <div class="container">
              <div class="d-flex col-12">
                
                <div class="foto-perfil  col-3 col-md-2 ">
                  <div className="logo-header">
                    <a href="/">
                    <Logo />       
                    </a>
                  </div>
                  
                  <FotoPerfil /> 
                </div>
                
                <div class="busca col-8 col-md-9 ">
                  <FormSearch />
                </div>

                <div class="menu  col-1">
                  
                  {/* lista de menu */}
                  <Navmenu_2 />
                </div>

              </div>
          </div>

      </header>
            </>

        );
    }
}

export default Headers;