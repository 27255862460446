import React from "react";
import LogoRede from "../assets/images/Logotheface.png"
class Logo extends React.Component{
    render(){
        return(
            <>
                <div className="Logo">
                    <img width={50} src={LogoRede }/>
                </div>
            
            </>

        );
    }
}

export default Logo;