import React from "react";
class MenuLateral extends React.Component{
    render(){
        return(
            <>
            <div className="menu-lateral">
                <div className="item-menu-lateral mais">
                    <a className="b-radius" href="#">
                    
                    </a>
                </div>
                <div className="item-menu-lateral globo">
                    <a className="b-radius" href="#">
                        
                    </a>
                </div>
                <div className="item-menu-lateral balao">
                    <a className="b-radius" href="#">
                        
                    </a>
                </div>
            </div>

            <div className="back-top b-radius top">
                
            </div>
            </>

        );
    }
}

export default MenuLateral;