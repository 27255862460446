import React from 'react';
import MenuLateral from "./components/MenuLateral";
import LeftBar from "./components/LeftBar";



import './index.css';
import './assets/style.css'

import Router from "./routers/routers.js";

class App extends React.Component{
  render(){
    return(
      <>
          <LeftBar />
          <MenuLateral />
      
          <Router/>
       
      </>
    );
  }
}

export default App;

