import Headers from '../Inc/Headers';
import Footer from '../Inc/Footer';
import Form_perfil from './Form-perfil';
export function Perfil() {

    return (
      <>
  

          <Headers />
          <div id='main'>
            
            <h1>Editar Perfil</h1>
            <Form_perfil/>  
          </div>
            <Footer />
      </> 
    ) 
  }
  export default Perfil;  