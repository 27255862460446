import React from "react";
import FotoPerfil from "./FotoPerfil";
class Coments extends React.Component{
    render(){
        return(
            <>
            <div className="coments col-12 d-flex">
                <div className="foto-perfil-coments col-1">
                    <a href="#">
                        <FotoPerfil />
                    </a>
                </div>
                <div className="post-perfil comentario col-11">
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pretium  congue viverra. Etiam posuere consequat ultrices. Vivamus auctor  suscipit ex, vitae consectetur quam fringilla quis. Morbi congue  volutpat felis id semper. 
                    </p>
                    <div className="coments resposta col-12 d-flex">
                        <div className="foto-perfil-coments col-1">
                            <a href="#">
                                <FotoPerfil />
                            </a>
                        </div>
                        <div className="post-perfil comentario col-11">
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pretium  congue viverra. Etiam posuere consequat ultrices. Vivamus auctor  suscipit ex, vitae consectetur quam fringilla quis. Morbi congue  volutpat felis id semper. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </>

        );
    }
}

export default Coments;