import Form from 'react-bootstrap/Form';

function Form_perfil() {
  return (
    <div className='container'>
        <div className='col-8 d-flex'>
            
            <Form className="mb-3 col-12 d-flex formulario">
            <Form.Group className="mb-3 col-4 " controlId="exampleForm.ControlInput1">
                <Form.Control className='col-12' type="name" placeholder="Seu Nome aqui" />
                <Form.Control className='col-12' type="email" placeholder="E-mail" />
                <Form.Control className='col-12' type="usuario" placeholder="Usuário" />
                <Form.Control className='col-12' type="genero" placeholder="Genero" />
            </Form.Group>
            <Form.Group className="mb-3 col-7" controlId="exampleForm.ControlInput1"    >
                <Form.Control type="Usuario" placeholder="Usuario" />
                <Form.Control className='col-12' as="textarea" rows={3} />
            </Form.Group>
            </Form>
            </div>
        </div>
    
  );
}

export default Form_perfil;