import React from "react";
class LeftBar extends React.Component{
    render(){
        return(
            <>
            <div className="menu-share-2">
                <div className="item-menu-lateral mais">
                    <a className="b-radius" href="#">
                    <i class="fa fa-heart" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="item-menu-lateral globo">
                    <a className="b-radius" href="#">
                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                    </a>
                </div>
                {/* <div className="item-menu-lateral seta">
                    <a className="b-radius" href="#">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </a>
                </div> */}
            </div>

            </>

        );
    }
}

export default LeftBar;