import React from "react";
import Slider from "react-slick";

function MultipleItems() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 3,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 8,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 5
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]

  };
  return (
    <div className="slider-container col-11">
      <Slider {...settings}>
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
        <div>
            <div className="perfil-feed">
                <a href="#">
                <img src="https://placehold.co/70x70"/>
                </a>
            </div>
        </div>
        
      </Slider>
    </div>
  );
}

export default MultipleItems;

