import React from "react";
import Posts from "../components/Posts";
import ListaFeeds from "../components/ListaFeeds";

class Main extends React.Component{
    render(){
        return(
            <>
            <div id="main">
                <ListaFeeds />
                <Posts />
                <Posts />   
                <Posts />
            </div>
            </>
        );
    }
}

export default Main;