import React from "react";
class Share extends React.Component{
    render(){
        return(
            <>
            <div className="menu-share">
                <div className="item-menu-lateral mais">
                    <a className="b-radius" href="#">
                    <i class="fa fa-heart" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="item-menu-lateral globo">
                    <a className="b-radius" href="#">
                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="item-menu-lateral seta">
                    <a className="b-radius" href="#">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </a>
                </div>
            </div>

            <a href="#page" className="back-top b-radius top">
                
            </a>
            </>

        );
    }
}

export default Share;