import Headers from '../Inc/Headers';
import Footer from '../Inc/Footer';
import Main from './Main';
export function Home() {

    return (
      <>
  

            <Headers />
            

              <Main />
            
            <Footer />
      </> 
    ) 
  }
  export default Home;  