import Headers from '../Inc/Headers';
import Footer from '../Inc/Footer';



const Configuracoes = () =>{
  return(
    <>

            <Headers />
            <div id='main'>

              <h2>Configuracoes</h2>

            </div>
            
            <Footer />

      
      
    </>
  )
}

export default Configuracoes;