import React from "react";
class FormSearch extends React.Component{
    render(){
        return(
            <>

            <form className="form-busca col-9">      
                <label className="col-12">
                    <input type="text" name="search" method="get" action="#acao" id="search" placeholder="pesquisar"></input>
                    <button className="Botao-submit" ><i class="fa fa-search"></i></button>
                </label>
            </form>
            </>

        );
    }
}

export default FormSearch;